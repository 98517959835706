// utils.js
export const indexOptions = [
  { value: "SENSEX", label: "SENSEX" },
  { value: "NIFTY", label: "NIFTY" },
  { value: "BANKNIFTY", label: "BANKNIFTY" },
  { value: "MIDCPNIFTY", label: "MIDCPNIFTY" },
  { value: "FINNIFTY", label: "FINNIFTY" },
];

const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
export const currentDayName = daysOfWeek[new Date().getDay()];
export const BASE_URL = process.env.REACT_APP_BASE_URL;
