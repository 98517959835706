// Strategy.jsx
import React, { useEffect, useState } from "react";
import axios from "axios";
import "./Strategy.css";
import Popup from "../components/popup";
import StrategyTable from "../components/StrategyTable";
import { BASE_URL } from "../utils/utils";

export const Strategy = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [allstrategy, setAllstrategy] = useState([]);
  const [selectedStrategy, setSelectedStrategy] = useState(null); // For updating strategy

  useEffect(() => {
    const fetchAllStrategies = async () => {
      const data = await axios.get(`${BASE_URL}/all_strategy`);
      setAllstrategy(data.data.data);
    };
    fetchAllStrategies();
  }, []);

  const handleDeleteStrategy = async () => {
    const data = await axios.get(`${BASE_URL}/all_strategy`);
    setAllstrategy(data.data.data);
  };

  const handleOpenPopup = (strategy = null) => {
    setSelectedStrategy(strategy);
    setIsPopupOpen(true);
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
    setSelectedStrategy(null); // Reset on close
  };

  return (
    <>
      <div>Add Strategy</div>
      <button onClick={() => handleOpenPopup()}>Create Strategy</button>
      <h2>All Strategy</h2>
      <StrategyTable data={allstrategy} onDelete={handleDeleteStrategy} onUpdate={handleOpenPopup} />
      {isPopupOpen && (
        <Popup
          onClose={handleClosePopup}
          strategy={selectedStrategy} // Pass selected strategy data for update
        />
      )}
    </>
  );
};
