import React from "react";
import "../Popup.css";

const InputField = ({ label, type, placeholder, value, onChange }) => (
  <div className="popup-row">
    <label>{label}</label>
    <input type={type} placeholder={placeholder} className="popup-input" value={value} onChange={onChange} required />
  </div>
);

export default InputField;
