import React from "react";
import "./Home.css";

export const Home = () => {
  return (
    <div className="Home">
      {/* Background video */}
      <video className="background-video" autoPlay muted loop>
        <source src="./background.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>

      {/* Content */}
      <div className="Home_div">
        <h1 className="Home_h1">Choose Broker</h1>
        <div className="Home_h1_links">
          <a className="Home_h1_links_shoonya" href="/shoonya">
            <img
              className="Home_h1_links_shoonya_img"
              src="./shoonya.png"
              alt="Shoonya"
            />
          </a>
          <a className="Home_h1_links_kotak" href="/kotak">
            <img
              className="Home_h1_links_kotak_img"
              src="./kotak.png"
              alt="Kotak"
            />
          </a>
        </div>
      </div>
    </div>
  );
};
