import React from "react";
import "./DaySelector.css";
import "../Popup.css";

const DaySelector = ({ selectedDays, onDayChange }) => {
  return (
    <div className="popup-row day-selector">
      <div className="day-selector">
        {["Mon", "Tue", "Wed", "Thu", "Fri"].map((day) => (
          <div
            key={day}
            className={`day-box ${
              selectedDays.includes(day) ? "selected" : ""
            }`}
            onClick={() => onDayChange(day)}
          >
            {day}
          </div>
        ))}
      </div>
    </div>
  );
};

export default DaySelector;
