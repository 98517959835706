import React from "react";
import "../Popup.css";

const SelectField = ({ label, options, value, onChange }) => (
  <div className="popup-row">
    <label>{label}</label>
    <select className="popup-select" value={value} onChange={onChange} required>
      {options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  </div>
);

export default SelectField;
