// src/App.js
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Strategy } from "./Pages/Strategy";
import { Home } from "./Pages/Home";
import "./App.css";
// import { ChartCom } from "./Pages/Chart";
import { Trade } from "./Pages/Trade";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/shoonya" element={<Trade />} />
        <Route path="/kotak" element={<Trade />} />
        <Route path="/strategy" element={<Strategy />} />
        {/* <Route path="/shoonya/chart" element={<ChartCom />} /> */}
      </Routes>
    </Router>
  );
}

export default App;
