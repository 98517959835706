import React, { useEffect, useState } from "react";
import StrategyTable from "../components/StrategyTable";
import { fetchTodayStrategies, login } from "../Apis/Api";
import axios from "axios";

export const Trade = () => {
  const [loginstatus, setLoginStatus] = useState(false);
  const [today_strategy, setTodayStrategy] = useState([]);
  const param = window.location.pathname.split("/").filter(Boolean).pop();
  const [tradeStatus, setTradeStatus] = useState("");

  useEffect(() => {
    const loginAndFetchStrategies = async () => {
      try {
        const isLoggedIn = await login(param);

        setLoginStatus(isLoggedIn);

        if (isLoggedIn) {
          const strategies = await fetchTodayStrategies();
          setTodayStrategy(strategies);
        }
      } catch (error) {
        console.error("Error during login or fetching strategies:", error);
      }
    };

    loginAndFetchStrategies();
  }, [param]);

  const Algostart = async () => {
    const entry = await axios.post("http://127.0.0.1:5000/algo/start", {
      data: today_strategy,
      broker: param,
    });
    setTradeStatus(entry.data.message);
  };

  return (
    <div>
      <div
        style={{
          padding: "20px",
        }}
      >
        {loginstatus ? "Logged In" : "Not Logged In"}
        <button onClick={Algostart}>Run</button>
        <h3>{tradeStatus}</h3>
        <a href="/shoonya/chart">Chart</a>
        <StrategyTable data={today_strategy} />
      </div>
    </div>
  );
};
