// services/shoonyaService.js
import axios from "axios";
import { BASE_URL, currentDayName } from "../utils/utils";

// Login API request
export const login = async (param) => {
  try {
    // const api = await axios.get(`${BASE_URL}/login/${param}`);
    const api = await axios.get(`${BASE_URL}/login/kotak`);
    console.log(api.data);

    return "True"; // Return true if login successful
  } catch (error) {
    console.error("Error during login:", error);
    throw error;
  }
};

// Fetch strategies for today
export const fetchTodayStrategies = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/all_strategy`);
    const ret = response.data;
    return ret.data.filter((item) =>
      item.selectedDays.includes(currentDayName)
    );
  } catch (error) {
    console.error("Error fetching strategies:", error);
    throw error;
  }
};
