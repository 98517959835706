// Popup.jsx
import React, { useState, useEffect } from "react";
import axios from "axios";
import { indexOptions } from "../utils/utils"; // Import options data
import "./Popup.css"; // Import styles
import InputField from "./Popup/InputField";
import SelectField from "./Popup/SelectField";
import DaySelector from "./Popup/DaySelector";

const Popup = ({ onClose, strategy }) => {
  const [strategyName, setStrategyName] = useState("");
  const [quantity, setquantity] = useState("");
  const [index, setIndex] = useState("SENSEX");
  const [actionType, setActionType] = useState("S");
  const [optionType, setOptionType] = useState("CE");
  const [cp, setCp] = useState("");
  const [entryTime, setEntryTime] = useState("09:22");
  const [sl, setSl] = useState("");
  const [exitTime, setExitTime] = useState("15:20");
  const [slType, setSlType] = useState("Percentage");
  const [selectedDays, setSelectedDays] = useState([]);

  // Set form data if editing an existing strategy
  useEffect(() => {
    if (strategy) {
      setStrategyName(strategy.strategyName);
      setquantity(strategy.quantity);
      setIndex(strategy.index);
      setActionType(strategy.actionType);
      setOptionType(strategy.optionType);
      setCp(strategy.cp);
      setEntryTime(strategy.entryTime);
      setSl(strategy.sl);
      setExitTime(strategy.exitTime);
      setSlType(strategy.slType);
      setSelectedDays(strategy.selectedDays || []);
    }
  }, [strategy]);

  const handleDayChange = (day) => {
    setSelectedDays((prevSelectedDays) =>
      prevSelectedDays.includes(day)
        ? prevSelectedDays.filter((d) => d !== day)
        : [...prevSelectedDays, day]
    );
  };

  const isFormValid = () => {
    return (
      strategyName &&
      quantity &&
      index &&
      actionType &&
      optionType &&
      cp &&
      entryTime &&
      exitTime &&
      sl &&
      slType &&
      selectedDays.length > 0
    );
  };

  const handleSubmit = async () => {
    if (!isFormValid()) {
      alert("Please fill in all required fields.");
      return;
    }

    const formData = {
      strategyName,
      quantity,
      index,
      actionType,
      optionType,
      cp,
      entryTime,
      exitTime,
      sl,
      slType,
      selectedDays,
    };

    try {
      if (strategy) {
        // If strategy exists, update the strategy
        await axios.put(
          `http://127.0.0.1:5000/update_strategy/${strategy._id.$oid}`,
          formData
        );
      } else {
        // If no strategy, it's a new submission
        await axios.post("http://127.0.0.1:5000/add_strategy", formData);
      }
      onClose(); // Close the popup
      window.location.reload();
    } catch (error) {
      console.error("Error submitting data:", error);
      alert("Failed to submit data. Please try again.");
    }
  };

  return (
    <div className="popup-overlay">
      <div className="popup">
        <span className="popup-close" onClick={onClose}>
          &times;
        </span>

        <h2 className="popup-title">
          {strategy ? "Update Strategy" : "Add New Strategy"}
        </h2>

        <div className="popup-content">
          <InputField
            label="Name:"
            type="text"
            placeholder="Enter strategy name"
            value={strategyName}
            onChange={(e) => setStrategyName(e.target.value)}
          />
          <InputField
            label="Quantity:"
            type="text"
            placeholder="Enter quantity"
            value={quantity}
            onChange={(e) => setquantity(e.target.value)}
          />

          {/* Row 2: Selectors for Index, Action Type, and Option Type */}
          <div className="popup-row">
            <SelectField
              label="Select Index:"
              options={indexOptions}
              value={index}
              onChange={(e) => setIndex(e.target.value)}
            />
            <SelectField
              label="Action Type:"
              options={[
                { value: "S", label: "Sell" },
                { value: "B", label: "Buy" },
              ]}
              value={actionType}
              onChange={(e) => setActionType(e.target.value)}
            />
            <SelectField
              label="Option Type:"
              options={[
                { value: "CE", label: "CE" },
                { value: "PE", label: "PE" },
              ]}
              value={optionType}
              onChange={(e) => setOptionType(e.target.value)}
            />
            <DaySelector
              selectedDays={selectedDays}
              onDayChange={handleDayChange}
            />
          </div>

          {/* Row 3: CP Input and Entry/Exit Time */}
          <div className="popup-row">
            <InputField
              label="CP Less Than:"
              type="number"
              placeholder="Premium"
              value={cp}
              onChange={(e) => setCp(e.target.value)}
            />

            <SelectField
              label="SL Type:"
              options={[
                { value: "Percentage", label: "Percentage" },
                { value: "Points", label: "Points" },
              ]}
              value={slType}
              onChange={(e) => setSlType(e.target.value)}
            />
            <InputField
              label="Stop Loss (SL):"
              type="number"
              placeholder="Enter Stop Loss"
              value={sl}
              onChange={(e) => setSl(e.target.value)}
            />
          </div>
          <div className="popup-row">
            <InputField
              label="Entry Time:"
              type="time"
              value={entryTime}
              onChange={(e) => setEntryTime(e.target.value)}
            />
            <InputField
              label="Exit Time:"
              type="time"
              value={exitTime}
              onChange={(e) => setExitTime(e.target.value)}
            />
          </div>
        </div>

        <div className="popup-actions">
          <button className="popup-button" onClick={handleSubmit}>
            {strategy ? "Update" : "Submit"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Popup;
