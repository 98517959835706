// StrategyTable.js
import React from "react";
import "./StrategyTable.css";
import axios from "axios";

const StrategyTable = ({ data, onDelete, onUpdate }) => {
  // Handle delete action
  const handleDelete = async (id) => {
    try {
      // Send a delete request to the backend
      await axios.post("http://127.0.0.1:5000/delete_strategy", { id });

      // Call the onDelete function to update the table data
      onDelete();
    } catch (error) {
      console.error("Error deleting strategy:", error);
      alert("Failed to delete strategy. Please try again.");
    }
  };

  return (
    <table className="strategy-table">
      <thead>
        <tr>
          <th>Strategy Name</th>
          <th>Quantity</th>
          <th>Index</th>
          <th>Action Type</th>
          <th>Option Type</th>
          <th>CP</th>
          <th>Entry Time</th>
          <th>Exit Time</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {data.map((item) => (
          <tr key={item._id.$oid}>
            <td>{item.strategyName}</td>
            <td>{item.quantity}</td>
            <td>{item.index}</td>
            <td>{item.actionType}</td>
            <td>{item.optionType}</td>
            <td>{item.cp}</td>
            <td>{item.entryTime}</td>
            <td>{item.exitTime}</td>
            <td>
              <button
                onClick={() => handleDelete(item._id.$oid)}
                className="delete-button"
              >
                Delete
              </button>
              <button
                onClick={() => onUpdate(item)}
                className="update-button"
              >
                Update
              </button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default StrategyTable;
